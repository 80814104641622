import React from "react"
import * as styles from "./HomePage.module.scss"
import Seo from "../../common/seo"
import HomeIntroSection from "./HomeIntroSection"

const HomePage = () => {
  return (
    <div className={styles.homePage}>
      <Seo title="Home" />
      <HomeIntroSection />
    </div>
  )
}

export default HomePage
