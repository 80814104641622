import React from "react"
import * as styles from "./HomeIntroSection.module.scss"

import { StaticImage } from "gatsby-plugin-image"

const leftPhone = "../../../../resources/images/smartphone_1.png"
const rightPhone = "../../../../resources/images/smartphone_2.png"
const googleBadge = "../../../../resources/images/store_bagde_google.png"
const appleBadge = "../../../../resources/images/store_badge_apple.png"

const HomeIntroSection = () => {
  return (
    <section id="intro" className={styles.homeIntroSection}>
      <div className={styles.sloganContainer}>
        <h1>
          Um{" "}
          <strong>
            cardápio digital
            <br />
            totalmente
          </strong>{" "}
          <span>gratuito</span>
          <br />
          para seu restaurante!
        </h1>
        <h2>
          Cadastre-se, gerencie seu cardápio e libere para seus clientes. Sem
          pegadinhas e sem enrolação.
        </h2>
      </div>
      <div className={styles.floatingPhonesContainer}>
        <StaticImage
          src={leftPhone}
          className={styles.leftPhoneImage}
          placeholder="blurred"
        />
        <StaticImage
          src={rightPhone}
          className={styles.rightPhoneImage}
          placeholder="blurred"
        />
      </div>
      <div className={styles.floatingCard}>
        <h3>Baixe agora o aplicativo</h3>
        <h4>
          Com o aplicativo, clientes do restaurante poderão ver seu cardápio
          <br />
          efetuando a leitura do QR Code posicionado na mesa.
        </h4>
        <div className={styles.badgeContainer}>
          <a
            href="https://play.google.com/store/apps/details?id=com.lutick.meelu"
            target="_blank"
            rel="noopener"
          >
            <StaticImage
              src={googleBadge}
              alt="Baixar Meelu na Google Play"
              placeholder="blurred"
            />
          </a>
          <a
            href="https://apps.apple.com/br/app/meelu/id1589660173"
            target="_blank"
            rel="noopener"
          >
            <StaticImage
              src={appleBadge}
              alt="Baixar Meelu na App Store"
              className={styles.appleBadge}
              placeholder="tracedSVG"
            />
          </a>
        </div>
      </div>
      <div className={styles.backgroundPanel} />
    </section>
  )
}

export default HomeIntroSection
