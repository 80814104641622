import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { lutickMeeluLogo } from "../../../resources/images"

import * as style from "./Footer.module.scss"

const Footer = ({ className }) => {
  return (
    <footer className={classNames(style.footer, className)}>
      <a href="https://lutick.com" target="_blank" rel="noopener">
        <img src={lutickMeeluLogo} alt="Lutick logo" />
      </a>
      <div>
        Meelu é um produto <strong>Lutick</strong>. Todos os direitos
        reservados.
      </div>
    </footer>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
}

export default Footer
