import React from "react"
import PropTypes from "prop-types"

import Header from "../Header"
import Footer from "../Footer"
import "./Layout.module.scss"
import "../../global.scss"

const Layout = ({ className, children }) => {
  return (
    <div id="layout" className={className}>
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
