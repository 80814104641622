import React from "react"

import { meeluLogo } from "../../../resources/images"

import * as styles from "./NavBar.module.scss"

const NavBar = () => {
  return (
    <div className={styles.navNar}>
      <img src={meeluLogo} alt="Meelu logo"/>
    </div>
  )
}

export default NavBar
