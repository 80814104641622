import React from "react"
import NavBar from "../NavBar"

import * as styles from "./Header.module.scss"

/**
 * Shared container holding a top background shape and a navigation bar
 */
const Header = () => {
  return (
    <header className={styles.header}>
      <NavBar />
    </header>
  )
}

export default Header
